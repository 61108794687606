$(document).ready(function() {
  var selector = $("input[type='email']");

  if (selector.val() !== '') {
    return;
  }
  $(".suggestion").parent().hide();

  var domains = [
    "aol.com",
    "facebook.com",
    "gmail.com",
    "googlemail.com",
    "hotmail.com",
    "hotmail.fr",
    "libero.it",
    "live.com",
    "live.fr",
    "mac.com",
    "mail.com",
    "mail.ru",
    "me.com",
    "msn.com",
    "outlook.com",
    "shaw.ca",
    "verizon.net",
    "yahoo.ca",
    "yahoo.com",
    "yahoo.fr",
    "ymail.com",
    "csrsaguenay.qc.ca"
  ];
  var secondLevelDomains = [
    "aol",
    "gmail",
    "hotmail",
    "live",
    "msn",
    "yahoo"
  ];
  var topLevelDomains = ["com", "net", "org", "qc.ca", "ca", "fr"];

  var EmailDomainSuggester = {
    domains: domains,
    bindTo: selector,

    init: function () {
      this.addElements();
      this.bindEvents();
    },

    addElements: function () {
      this.datalist = $("<datalist />", {
        id: "email-options"
      }).insertAfter(this.bindTo);
      this.bindTo.attr("list", "email-options");
    },

    bindEvents: function () {
      this.bindTo.bind("keypress", this.testValue);
    },

    testValue: function (event) {
      var el = $(this), value = el.val();

      if (value.indexOf("@") > -1 && value.length >= value.indexOf("@") + 1) {
        value = value.split("@")[0];
        EmailDomainSuggester.addDatalist(value);
      } else {
        EmailDomainSuggester.datalist.empty();
      }
    },

    addDatalist: function (value) {
      var i, newOptionsString = "";
      for (i = 0; i < this.domains.length; i++) {
        newOptionsString += "<option value='" + value + "@" + this.domains[i] + "'>";
      }
      this.datalist.html(newOptionsString);
    }
  };

  EmailDomainSuggester.init();

  $(selector).on("blur", function (event) {
    EmailDomainSuggester.datalist.empty();
    var currentElement = event.target;

    $(currentElement).on("keyup", function (event) {
      $(this).find("~ span").hide(200);
      $(this).removeClass("invalid");
    });

    $(currentElement).mailcheck({
      domains: domains,
      secondLevelDomains: secondLevelDomains,
      topLevelDomains: topLevelDomains,
      suggested: function (element, suggestion) {
        $(currentElement).find("~ span > .suggestion").html(
          "Utiliser plutôt <a href='#' class='suggestedEmail'>" +
            suggestion.full +
            "</a>?"
        );
        $(currentElement).addClass("invalid");
        $(currentElement).find("~ span > .suggestion").parent().show(200);
      },
      empty: function (element) {}
    });

    $(currentElement).find("~ span > .suggestion a")
      .on("click", function (event) {
        $(currentElement).val($(this).text());
        $(currentElement).find("~ span").hide(200);
        $(currentElement).removeClass("invalid");
        event.preventDefault();
      })
  });
});
